
import React, { useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Paper,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  Modal,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import useAxios from "../../../hooks/useAxios";
import apiEndpoints from "../../../utils/ApiEndpoints";
import ConfirmationDialog from "../../dialogBox/ConfirmationDialogBox";
import AuthContext from "../../../context/AuthContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// Table to be displayed in DataImports page
export default function DataImportTable({
  data,
  tableHeaders,
}) {
  const [confirmDialog, setConfirmDialog] = useState({
    title: "",
    subTitle: "",
    isOpen: false,
  });

  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { setPauseTimer } = useContext(AuthContext);


  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const api = useAxios();
  async function importData(fileName) {
    setConfirmDialog({
      title: "Confirmation",
      subTitle: `Are you sure you would like to import file ${fileName} ?`, 
      isOpen: true,
      onConfirm: async () => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setPauseTimer(true);
        setOpenModal(true);

        const response = await api.post(
          apiEndpoints.dataImport_importFile.replace("{fileName}", fileName)
        )
          .then((res) => {
            setOpenModal(false);
            setOpenSuccessModal(true);
            setPauseTimer(false);
          });
      },
    });

  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell scope="row">{row.uri}</StyledTableCell>
                <StyledTableCell >{row.contentLength}</StyledTableCell>
                <StyledTableCell>{row.lastModifiedDate}</StyledTableCell>
                <StyledTableCell >
                  <Button
                    onClick={() => importData(row.uri)}
                    variant="contained">Import</Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Confirmation dialog box to start import data */}
      <ConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Modal box to wait until import data is completely processed*/}
      <Modal open={openModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Import Data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Importing data is in progress. Please, wait until import is finished.
          </Typography>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress size={50} />
          </Box>
        </Box>
      </Modal>

      {/* Success modal to report import data is completed*/}
      <Modal open={openSuccessModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Import Data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Importing data was successful.
          </Typography>
          <Box display='flex' justifyContent='center' alignItems='center' sx={{marginTop: 1, marginBottom:1}}>
            <CheckIcon sx={{ fontSize: 80 }} color="success" />
          </Box>
          <Button onClick={() => handleCloseSuccessModal()}>Close</Button>
        </Box>
      </Modal>
    </>
  );
}