import { createContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import SHA512 from 'crypto-js/sha512';
import { useLocation, useNavigate } from 'react-router-dom';
import apiEndpoints from '../utils/ApiEndpoints';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() =>
    sessionStorage.getItem('authTokens')
      ? jwt_decode(JSON.parse(sessionStorage.getItem('authTokens')).accessToken)
      : null
  );
  const [authTokens, setAuthTokens] = useState(() =>
    sessionStorage.getItem('authTokens')
      ? JSON.parse(sessionStorage.getItem('authTokens'))
      : null
  );

  const [providerId, setProviderId] = useState(() =>
    JSON.parse(sessionStorage.getItem('providerId') || null)
  );

  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [pauseTimer, setPauseTimer] = useState(false); //to pause timer in session timeout (autologout) when necessary
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || '/'; //to redirect the user to the page that the user is coming from

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.accessToken));
    }
    setIsLoading(false);
  }, [authTokens, isLoading]);

  const signInUser = async (loginDTO) => {
    const hashedPassword = SHA512(loginDTO.password).toString();
    const hashedLoginDTO = { email: loginDTO.email, password: hashedPassword };
    axios
      .post(apiEndpoints.auth_login, hashedLoginDTO)
      .then((response) => {
        if (response.data.success) {
          setAuthTokens(response.data.tokenPair);
          setUser(jwt_decode(response.data.tokenPair.accessToken));
          sessionStorage.setItem(
            'authTokens',
            JSON.stringify(response.data.tokenPair)
          );
          setError(null);
          sessionStorage.setItem(
            'providerId',
            JSON.stringify(response.data.providerId)
          );
          setProviderId(response.data.providerId);
          navigate(from, { replace: true });
        }
      })
      .catch((error) => {
        setAuthTokens(null);
        setUser(null);
        setError(error.response.data.errors[0]);
      });
  };

  const signOutUser = () => {
    setAuthTokens(null);
    setUser(null);
    setProviderId(null);
    sessionStorage.removeItem('authTokens');
    sessionStorage.removeItem('providerId');
    navigate('/login');
  };

  const contextData = {
    user: user,
    authTokens: authTokens,
    error: error,
    setError: setError,
    setUser: setUser,
    setAuthTokens: setAuthTokens,
    signInUser: signInUser,
    signOutUser: signOutUser,
    providerId: providerId,
    pauseTimer: pauseTimer,
    setPauseTimer: setPauseTimer,
  };
  return (
    <AuthContext.Provider value={contextData}>
      {isLoading ? null : children}
    </AuthContext.Provider>
  );
};
