import React, { useState, useEffect } from "react";
import GridLayout from "../../components/common/GridLayout/GridLayout";
import DataImportTable from "../../components/common/table/DataImportTable";
import apiEndpoints from '../../utils/ApiEndpoints';
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";

/* 
 * Handles the csv data imports from list of files in WebDAV client.
 * The data import is specifically to update the EmergencyDatas table in database.
 */
const DataImports = () => {
  const api = useAxios();
  const [filesList, setfilesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Gets list of csv files in webDAB client
  useEffect(() => {
    async function getFilesList() {

      const response = await api.get(
        apiEndpoints.dataImport_listFiles
      );

      setfilesList(response.data);
      setIsLoading(false);
    };

    getFilesList();

  }, []);

  const tableHeaders =
    [
      "File name",
      "Size",
      "Last modified",
      "Action"
    ]
  return (
    <GridLayout>
      {isLoading ?
        (
          <Paper sx={{ padding: 8, display: "flex", justifyContent: "center" }}>
            <CircularProgress size={100} />
          </Paper>
        ) :
        (
          <Paper sx={{ padding: 3 }}>
            <Typography variant="h4" sx={{ paddingBottom: 1 }}>Data Import</Typography>
            <>
              <DataImportTable
                data={filesList}
                tableHeaders={tableHeaders}
              />
            </>
          </Paper>
        )}
    </GridLayout>
  )
}

export default DataImports;